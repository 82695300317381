import { cn } from '@hapstack/common'
import type { IconName } from '@hapstack/ui'
import { BrandedIcon, H1, H2 } from '@hapstack/ui'
import type { PropsWithChildren } from 'react'

export const PageHeader = ({
  title,
  icon,
}: {
  title: React.ReactNode
  icon: IconName
}) => {
  return (
    <div className="flex items-center gap-4 leading-none">
      <BrandedIcon
        name={icon}
        size="lg"
      />
      <div>
        <H1>{title}</H1>
      </div>
    </div>
  )
}

export const PageSubheader = ({
  title,
  description,
  className,
  children,
}: {
  title: React.ReactNode
  description?: React.ReactNode
  className?: string
} & PropsWithChildren) => {
  return (
    <div
      className={cn(
        'flex min-h-10 w-full items-center justify-between border-b border-border pb-1.5 leading-none',
        className
      )}
    >
      <div className="flex gap-3">
        <div className="flex flex-col gap-1">
          <H2>{title}</H2>
          {description ? (
            <div className="text-sm text-muted-foreground">{description}</div>
          ) : null}
        </div>
      </div>
      {children}
    </div>
  )
}
